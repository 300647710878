import React from 'react';
import { graphql } from 'gatsby';
import clsx from 'clsx';

import SEO from '../components/seo';
import { LayoutContent } from '../components/layout/layout-content';

import styles from './markdown-page.module.scss';

const MarkdownPage = ({ data }) => {
  const {
    html,
    frontmatter: { title, className },
  } = data.markdownRemark;

  return (
    <LayoutContent className={styles.container}>
      <SEO title={`Andreas Straub | ${title}`} />
      <div className={clsx(styles.wrapper, styles[className])}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.mainContent} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </LayoutContent>
  );
};

export const query = graphql`
  query($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        className
      }
      html
    }
  }
`;

export default MarkdownPage;
